.fade-enter {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
}

.fade-exit {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(-100%);
}

.loading-spinner {
    color: #333;
    text-align: center;
}
